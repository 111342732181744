import { defineStore } from 'pinia'
import {listToObject, getName } from '../globalMethods.js'


export const useSessionStore = defineStore('session', {
    state: () => {
        return {
            songs: [],
            collections: [],
            accessToken: "",
            sessionReady: false,
            errorMsg: "",
            cantGetBackend: false,
            lang: "no"
        }
    },
    actions: {
        async setLanguage(lang){
            this.lang = lang
        },
        async getAllData(accessToken, languageReload) {
            if ((this.songs.length <= 0 && this.collections.length <= 0)||languageReload) {
                this.sessionReady = false
                console.log('Start getting backend')

                // Collections
                const collections = await get_data('Collections', accessToken)
                
                this.collections = collections
                let collection_list = []
                for (const collection of collections) {
                    collection_list.push(collection.id)
                }

                let collection_names = {}
                for (const collection of collections) {
                    collection_names[collection.id] = {'name': getName(collection.name), 'keys': getName(collection.keys)}
                }

                // Songs
                const songs = await get_data('Songs', accessToken)

                //Countries
                const countries = listToObject(await get_data('Countries', accessToken), 'countryCode')

                // Copyrights
                const copyrights = listToObject(await get_data('Copyrights', accessToken), 'id')

                // Contributors
                const contributors_list = await get_data('Contributors', accessToken) 
                let contributors = listToObject(contributors_list, 'id')

                // Song dict
                let song_dict = {} 
                for (const collection of collection_list) {
                    song_dict[collection] = []
                }

                // Add on Metadata 
                for (const song of songs) {
                    for (const collection of song.collections) {
                        collection['name'] = collection_names[collection.id].name
                        collection['keys'] = collection_names[collection.id].keys
                    }

                    for (const participant of song.participants) {
                        participant['participant_data'] = contributors[participant.contributorId].item

                    }
                    for (const copyright of song.copyrights) {
                        if (copyright.copyrightId=="00000000-0000-0000-0000-000000000000"){
                            copyright["name"] = 'None'
                        } else {
                        copyright['name'] = copyrights[copyright.copyrightId].name}
                    }
                    for (const origin of song.origins) {
                        if (Object.prototype.hasOwnProperty.call(countries, origin['country'])){
                            origin['countryName'] = countries[origin.country].name
                        } else {
                            origin['countryName'] = 'None'
                        }
                    }

                    // Add this song to the right collection
                    song_dict[song.collections[0].id].push(song)
                }

                // Sort all songs within each collection
                let sorted_song_dict = {}
                for (const [key, value] of Object.entries(song_dict)) {
                    sorted_song_dict[key] = value.sort(function (first, second) {
                        return first.collections[0].number - second.collections[0].number;
                    });
                }
                this.songs = sorted_song_dict
                this.sessionReady = true
                console.log('Finished getting backend')

            }
        },
    },
})


function processRequest(response){
    return new Promise((resolve) => {
        resolve(response)
    })
}

async function get_data(model, accessToken) {
    const axios = require('axios')
    const api_url = process.env.VUE_APP_SONGTREASURES_API_URL
    const response = await axios.get(api_url + model, {
        headers: {
            'Authorization': 'Bearer ' + accessToken,
            'X-Api-Version': '3.0',
        }
    })
    console.log(model + ' recieved')
    const processResponse = await processRequest(response)
    return processResponse.data.result
}
