import { getAuth, signInWithEmailAndPassword, signInWithPopup, GoogleAuthProvider, OAuthProvider } from "firebase/auth"
import { initializeApp } from "firebase/app"
import router from "../router";
import { useSessionStore } from '../store/session';

const firebaseConfig = {
    apiKey: process.env.VUE_APP_FIREBASE_API_KEY,
    authDomain: process.env.VUE_APP_FIREBASE_AUTH_DOMAIN,
    projectId: process.env.VUE_APP_FIREBASE_PROJECT_ID,
    storageBucket: process.env.VUE_APP_FIREBASE_STORAGE_BUCKET,
    messagingSenderId: process.env.VUE_APP_FIREBASE_MESSAGING_SENDER_ID,
    appId: process.env.VUE_APP_FIREBASE_APP_ID,
    measurementId: process.env.VUE_APP_FIREBASE_MEASUREMENT_ID,
};

const app = initializeApp(firebaseConfig);
export const auth = getAuth(app);

//Configure providers
const googleAuthProvider = new GoogleAuthProvider();
const appleAuthProvider = new OAuthProvider("apple.com");
appleAuthProvider.addScope("email");
appleAuthProvider.addScope("name");

export async function loginWithEmailAndPassword(email, password) {
    signInWithEmailAndPassword(auth, email, password)
        .then(() => {
            router.push({ name: 'main' });
/*             if (store.usersWithAccess.includes(email.toLowerCase())) {
                router.push({ name: 'main' });
            } else {
                alert('You dont have access to this website, please contact service@skjulte-skatter.no for access')} */
        })
        .catch((error) => {
            router.push({ name: 'login' });
            const errorCode = error.code;
            const errorMessage = error.message
            console.log(errorCode);
            console.log(errorMessage);
            alert(errorCode, errorMessage)
        });
}

export async function loginWithProvider(providerName) {
    let provider = undefined;
    switch (providerName) {
        case "google":
            provider = googleAuthProvider;
            break;
        case "apple":
            provider = appleAuthProvider;
            break;
        default:
            return;
    }

    signInWithPopup(auth, provider)
        .then(() => {
            router.push({ name: 'main' });
/*             if (store.usersWithAccess.includes(auth.currentUser.email.toLowerCase())) {
                router.push({ name: 'main' });
            } else {
                alert('You dont have access to this website, please contact service@skjulte-skatter.no for access')  }*/
            })
        .catch((error) => {
            router.push({ name: 'login' });
            const errorCode = error.code;
            const errorMessage = error.message
            console.log(errorCode);
            console.log(errorMessage);
            alert(errorCode, errorMessage)
        })
}

export function getCurrentUserPromise() {
    let userLoaded = false || !!getAuth().currentUser;
    let hasAccess = true
    return new Promise((resolve, reject) => {
        if (userLoaded && hasAccess) {
            resolve(!!(auth.currentUser) && hasAccess);
            return;
        }
        const unsubscribe = auth.onAuthStateChanged(async (user) => {
            userLoaded = true;
            unsubscribe();
            resolve(!!(user) && hasAccess);
            if (user != null) { // Fires only when user logs in
                await userLoggedInCallback();
            }
        }, reject);
    });
}

async function userLoggedInCallback() {
    //Should be done without await maybe for asynchronous running
    const store = useSessionStore();
    store.sessionInitialized = true;
    return;
}

export function getUser() {
    const auth = getAuth();
    if (auth.currentUser) {
        return auth.currentUser
    }
}