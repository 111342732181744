<template>
    <v-row justify="center">
        <v-dialog persistent v-model="dialog" width="800">
            <v-card class="overflow-y-auto" style="padding: 15px;">
                <div>
                    <v-window>
                        <v-row style="text-align: right;">
                            <v-col cols="auto">
                                <p @click="changeLocale('en')"
                                    :class="{ 'language-option': true, 'bold-text': lang === 'en' }">{{ $t('english') }}</p>
                            </v-col>
                            <v-col cols="auto">
                                <p>|</p>
                            </v-col>
                            <v-col cols="auto">
                                <p @click="changeLocale('no')"
                                    :class="{ 'language-option': true, 'bold-text': lang === 'no' }">{{ $t('norwegian') }}
                                </p>
                            </v-col>
                        </v-row>
                        <v-card-title>
                            <span class="text-h5">{{ $t('terms.title') }}</span>
                        </v-card-title>
                        <v-card-text>
                            <p>{{ $t('terms.intro') }}</p>
                            <br>
                            <p style="font-weight: bold;">{{ $t('terms.subTitle1') }}</p>
                            <br>
                            <p> {{ $t('terms.1a') }}</p>
                            <br>
                            <p><em>{{ $t('terms.1b') }}</em>
                            </p>
                            <br>
                            <p> {{ $t('terms.1c') }}</p>
                            <br><br>
                            <p><strong>{{ $t('terms.subTitle2') }}</strong></p>
                            <br>
                            <p><em>{{ $t('terms.2a') }}</em>
                            </p><br>
                            <p>{{ $t('terms.2b') }}</p>
                            <br>
                            <p>{{ $t('terms.2c') }}</p>
                        </v-card-text>
                        <v-card-actions>
                            <v-spacer></v-spacer>
                            <v-btn color="green-darken-1" variant="text" @click="onAgree()">
                                {{ $t('terms.accept') }}
                            </v-btn>
                        </v-card-actions>
                    </v-window>
                </div>
            </v-card>
        </v-dialog>
    </v-row>
</template>

<script>
export default {
    name: 'OverlayComponent',
    data() {
        return {
            tab: null,
            lang: this.$i18n.locale,
            dialog: true
        };
    },
    methods: {
        changeLocale(locale) {
            this.$i18n.locale = locale;
        },
        onAgree() {
            this.$emit('agree-terms')
        },
    },
}
</script>

<style>
.language-option {
    cursor: pointer;
}

.language-option:hover {
    font-weight: bold;
}
</style>