<template>
 <v-app class="d-flex flex-column min-vh-90">
  <v-main>
    <RouterView />
  </v-main>
    <v-footer 
      style="background-color: grey; color: white; text-align:center; display:flex; flex-direction:column; justify-content:center;"
    >
      <v-container>
        <v-row>
          <v-col>
            <p style="text-align:center">{{ $t('footer1') }}</p>
            <p style="text-align:center">{{ $t('footer2') }}</p>
          </v-col>
        </v-row>
      </v-container>
    </v-footer>
  </v-app>
</template>

<script>
import { RouterView } from 'vue-router'

export default {
  name: 'App',
  components: {
    RouterView,
  },
}
</script>


<style lang="scss">
/* Ensure the content area takes up at least 100% of the viewport height */
#app {
  height: 100vh;
}

html {
  --st-main-color: var(--st-color-primary) --st-color-background-dark: var(--st-color-ui-lm-medium);
  --st-color-background-medium: var(--st-color-ui-lm-light);
  --st-color-background-light: white;
  --st-color-border: var(--st-color-ui-lm-dark);
  --st-color-text: var(--st-color-text-lm);
  --st-color-text-inverted: var(--st-color-text-dm);
  background-color: var(--st-color-background-dark);
  color: var(--st-color-text);
  overflow-y: scroll;

  scroll-behavior: smooth;

  &.dark {
    --st-color-secondary: #ffffff;
    --st-color-background-dark: var(--st-color-ui-dm-dark);
    --st-color-background-medium: var(--st-color-ui-dm-medium);
    --st-color-background-light: var(--st-color-ui-dm-light);
    --st-color-border: var(--st-color-ui-dm-light);
    --st-color-text: var(--st-color-text-dm);
    --st-color-text-inverted: var(--st-color-text-lm);

    .button--secondary {
      color: var(--st-color-text-lm);
    }
  }
}
</style>