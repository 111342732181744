import { createRouter, createWebHistory } from "vue-router";
import LoginView from "../views/LoginView.vue";
import { getCurrentUserPromise } from '../services/auth';

const routes = [
  {
    path: "/",
    name: "login",
    component: LoginView,
  },

  {
    path: "/main",
    name: "main",
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/MainView.vue"),
    meta: {
      authRequired: true,
    },
  },
  {
    path: "/aboutus",
    name: "aboutus",
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/AboutUs.vue"),
    meta: {
      authRequired: true,
    },
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

router.beforeEach(async (to, from, next) => {
  if (to.meta.scrollUp === true && from.meta.scrollUp === true) {
    setTimeout(() => {
      window.scrollTo(0, 0);
    }, 150);
  }

  let allowedUser = undefined;
  const requiresAuth = to.matched.some((record) => record.meta.authRequired)

  if (requiresAuth !== undefined) {
    allowedUser = await getCurrentUserPromise()
  }

  //If the site requires auth and the user is not logged in: redirect to login
  if (requiresAuth && allowedUser === false) {
    next({ name: "login" });
  }

  //If the site requires the user to be logged off and the user is logged in: redirect to dashboard
  else if (requiresAuth === false && allowedUser)
    next({ name: "main" });

  else next();
});

export default router