const XLSX = require('xlsx');
const { saveAs } = require('file-saver');
import moment from 'moment';
import { useSessionStore } from './store/session';

// Gets the default the norwegian name, otherwise it takes the next in the object
export function getName(dict) {
    const store = useSessionStore()
    if (store.lang in dict) {
        return dict[store.lang];
      }
    else if ('no' in dict) {
        return dict.no
    } else {
        return Object.values(dict)[0]
    }
}

// Exports list to objects
export function listToObject(list, key) {
    let new_dict = {}
    for (const element of list) {
        new_dict[element[key]] = element
    }
    return new_dict
}

// Creates the subtitles in the browser/expandable list that says something about the participants and which collection the piece is from
export function getParticipants(participants) {
    var pdict = {}
    let text = ''
    for (let i = 0, len = participants.length; i < len; i++) {
        let p_type = participants[i].type.charAt(0).toUpperCase() + participants[i].type.slice(1)
        if (Object.keys(pdict).includes(p_type)) {
            pdict[p_type] = pdict[p_type] + ', ' + participants[i].participant_data.name
        } else {
            pdict[p_type] = p_type + ': ' + participants[i].participant_data.name
        }
    }
    let c = 0
    for (const p of Object.values(pdict)) {
        if (c === 0) {
            text += p
        } else {
            text += ' | ' + p
        }
        c += 1
    }
    return text
}

// Checks if all values in a list is equal
const allEqual = arr => arr.every(val => val === arr[0]);

// The functions that takes the selected songs and gets ready for excel
export function exportExcel(selectedSongs, MetaDict, i18n) {

    let exportList = []

    // Loops through selected songs
    for (const selectedSong of Object.values(selectedSongs)) {
        var songDict = {}

        // Loops through the keys of the MetaDict (which is the values chosen to be included in the export)
        for (const [metaKey, metaValue] of Object.entries(MetaDict)) {
            
            // Initialize empty in case of no hit
            songDict[metaValue] = ''

            // Get title
            if (metaKey === 'title') {
                songDict[metaValue] = getName(selectedSong.name)
            } // Song Number
            else if (metaKey === 'songNumber') {    
                songDict[metaValue] = selectedSong.collections[0].number
            } // Collection
            else if (metaKey === 'collection') {
                songDict[metaValue] = selectedSong.collections[0].name
            } // Author, composer and arranger (they are in same sub-Objective)
            else if (['author', 'composer', 'arranger'].includes(metaKey)) {
                for (const participant of Object.values(selectedSong.participants)) {
                    if (metaKey === participant.type) {
                        songDict[metaValue] = songDict[metaValue] !== '' ? songDict[metaValue] + ', ' + participant.participant_data.name : participant.participant_data.name
                    }
                }
            } // Year of composing, publishing or composing (first hit is selected in respective order) 
            else if ('year' === metaKey) {
                if (selectedSong.yearWritten !== null) {
                    songDict[metaValue] = i18n.t('writtenIn') + selectedSong.yearWritten
                } else if (selectedSong.yearPublished !== null) {
                    songDict[metaValue] = i18n.t('publishedIn') + selectedSong.yearPublished
                } else if (selectedSong.yearComposed !== null) {
                    songDict[metaValue] = i18n.t('composedIn') + selectedSong.yearComposed
                }
            } // Copyright. Logic: "Melody: Copyright, Text: Copyright". If same copyright "Melody and text: Copyright" 
            else if ('copyright' === metaKey) {
                let prefix = []
                let copyrights = []
                for (const copyright of Object.values(selectedSong.copyrights)) {
                    if ('melody' === copyright.type && typeof copyright.name !== undefined) {
                        prefix.push(i18n.t('melody'))
                        copyrights.push(getName(copyright.name))
                    } else if ('text' === copyright.type && typeof copyright.name !== undefined) {
                        prefix.push(i18n.t('text'))
                        copyrights.push(getName(copyright.name))
                    }
                    if (allEqual(copyrights)) {
                        songDict[metaValue] = prefix.join(i18n.t('and')) + ' © ' + copyrights[0]
                    } else {
                        let text = ''
                        for (var i = 0; i < copyrights.length; i++) { text += prefix[i] + ' © ' + copyrights[i] }
                        songDict[metaValue] = text
                    }
                }
            } // Country of origin 
            else if (metaKey === 'country') {
                for (const origin of Object.values(selectedSong.origins)) {
                    if ('text' === origin.type) {
                        songDict[metaValue] = getName(origin.countryName)
                    } else if ('originMelody' == metaKey && 'melody' === origin.type) {
                        songDict[metaValue] = getName(origin.description)
                    }
                }
            } // Set unknown if none of this keys match the loop key
            else if (metaKey !== 'country') {
                songDict[metaValue] = typeof selectedSong[metaKey] !== undefined ? selectedSong[metaKey] : i18n.t('unknown')
            }
            // Again if the loop key doesnt match any, then set unknown
            if (songDict[metaValue] === '' || typeof songDict[metaValue] === 'undefined' || songDict[metaValue] == null) { songDict[metaValue] = i18n.t('unknown') }
        }
        // Check if composer og author is set unknown, and fill with origin text or melody if present
        for (const origin of Object.values(selectedSong.origins)) {

            if ('text' === origin.type && (typeof songDict[MetaDict['author']] === undefined || songDict[MetaDict['author']] === i18n.t('unknown'))) {
                songDict[MetaDict['author']] = getName(origin.description)
            } else if ('melody' === origin.type && (typeof songDict[MetaDict['composer']] === undefined || songDict[MetaDict['composer']] === i18n.t('unknown'))) {
                songDict[MetaDict['composer']] = getName(origin.description)
            }
        }
        exportList.push(songDict)
    }
    generateExcel(exportList, MetaDict, i18n)
}

// Generates the excel sheets
function generateExcel(data, MetaDict, i18n) {
    const workbook = XLSX.utils.book_new();

    // Chooses columns
    const keys = Object.values(MetaDict);
    // const column_titles = Object.values(MetaDict);

    // Sets the column titles on row 2
    const worksheet = XLSX.utils.json_to_sheet(data, {
        header: keys, origin: { r: 2, c: 0 }
    });
    // Adjust column widths to fit content
    const columns = keys;
    const columnWidths = columns.map(column => {
        const columnData = data.map(row => row[column]);
        const columnMaxWidth = Math.max(
            ...columnData.map(value => value.toString().length)
        );
        return { width: columnMaxWidth + 2 }; // Add extra padding to width
    });
    worksheet['!cols'] = columnWidths;

    // Insert empty rows and placeholder row at the top
    XLSX.utils.sheet_add_aoa(worksheet, [[]]);
    XLSX.utils.sheet_add_aoa(worksheet, [[i18n.t('requiredFieldMsg')]]);
    XLSX.utils.sheet_add_aoa(worksheet, [[]]);

    XLSX.utils.book_append_sheet(workbook, worksheet, 'Sheet1');
    const now = moment().format('DDMMYYYY_HHmm');
    const filename = `${now}_${i18n.t('crediting')}.xlsx`;

    const excelData = XLSX.write(workbook, { type: 'binary', bookType: 'xlsx' });
    const blob = new Blob([s2ab(excelData)], { type: 'application/octet-stream' });
    saveAs(blob, filename);
}

// Decoding norwegian letters frm microsoft to utf-8
function s2ab(s) {
    const buf = new ArrayBuffer(s.length);
    const view = new Uint8Array(buf);
    for (let i = 0; i < s.length; i++) {
        view[i] = s.charCodeAt(i) & 0xFF;
    }
    return buf;
}