<template>
    <v-container>
      <v-row style="justify-content: right">
        <v-col style="text-align: left;">
          <v-row align="top">
            <v-col cols="auto">
              <a href="https://www.songtreasures.org" target="_blank" style="text-decoration: underline; color: #b49e78;">Songtreasures.org</a>
            </v-col>
            <v-col cols="auto">
              <p @click="changeLocale('en')" :class="{ 'language-option': true, 'bold-text': $i18n.locale === 'en' }">{{
                $t('english') }}</p>
            </v-col>
            <v-col cols="auto">
              <p>|</p>
            </v-col>
            <v-col cols="auto">
              <p @click="changeLocale('no')" :class="{ 'language-option': true, 'bold-text': $i18n.locale === 'no' }">{{
                $t('norwegian') }}</p>
            </v-col>

          </v-row>
        </v-col>
        <v-col style="text-align: right;">
        </v-col>
      </v-row>
      <v-row class=row>
        <v-img style="margin-top: 0px" class="img" max-height="120px" :src="logoPath"></v-img>
      </v-row>
    </v-container>
    <OverlayComponent v-if="dialog" @agree-terms="agreeTerms" />
    <v-sheet style="margin-top: 120px; margin-bottom: 120px" width="300" class="mx-auto">
        <div class="d-flex flex-column">
            <v-btn color="white" style="height: 45px" class="mt-4" @click="showForm">
                <v-avatar style="padding: 6px">
                    <v-img class="img" src="../assets/email.svg"></v-img>
                </v-avatar>
                {{ $t('logInWith') }} email
            </v-btn>
            <v-form v-if="showform" @submit.prevent="loginEmailPassword" ref="form"
                style="margin-top: 40px; margin-bottom: 40px">
                <v-text-field v-model="email" id="email" label="Email" required
                    :rules="[v => !!v || 'Please input email']"></v-text-field>
                <v-text-field type=password v-model="password" id="password" :label="$t('password')" required
                    :rules="[v => !!v || 'Please input password']"></v-text-field>

                <v-btn type="submit" style="height: 45px" color="#E1C390" class="mt-4" block>
                    {{ $t("logIn") }}
                </v-btn>
            </v-form>
            <v-btn color="white" style="height: 45px" class="mt-4" @click="loginProvider('google')">
                <v-avatar style="padding: 6px">
                    <v-img class="img" src="../assets/Google.png"></v-img>
                </v-avatar>
                {{ $t('logInWith') }} Google
            </v-btn>
            <v-btn color="white" style="height: 45px" class="mt-4" @click="loginProvider('apple')">
                <v-avatar style="padding: 6px">
                    <v-img class="img" src="../assets/Apple.png"></v-img>
                </v-avatar> {{ $t('logInWith') }} Apple
            </v-btn>


        </div>
    </v-sheet>
</template>

<script>
import { loginWithEmailAndPassword, loginWithProvider } from '../services/auth';
import OverlayComponent from '../components/Overlay.vue';
import { useSessionStore } from '../store/session';

export default {
    name: 'LoginView',
    components: {
        OverlayComponent,
    },
    data() {
        return {
            email: "",
            password: "",
            showform: false,
            errormessage: "",
            currentUser: "",
            beenLoggedIn: false,
            dialog: false,
            lang: this.$i18n.locale,
        };
    },
    mounted() {
        const store = useSessionStore()
        store.setLanguage(this.$i18n.locale)
    },
    computed: {
        logoPath() {
            return `/img/logo_${this.$i18n.locale}.jpg`;
        },
    },
    methods: {
        changeLocale(locale) {
            this.$i18n.locale = locale;
            console.log(this.$i18n.locale)
        },
        agreeTerms() {
            console.log('User agreed to terms and conditions')
            this.dialog = false
        },
        async loginEmailPassword(submitEvent) {
            this.dialog = true
            await new Promise(resolve => {
                const checkDialog = () => {
                    if (!this.dialog) {
                        resolve(); // Resolve the promise when dialog is closed
                    } else {
                        setTimeout(checkDialog, 100); // Check again after a short delay
                    }
                };
                checkDialog(); // Start checking dialog status
            });
            this.email = submitEvent.target.elements.email.value;
            this.password = submitEvent.target.elements.password.value;
            loginWithEmailAndPassword(this.email, this.password)

        },
        async loginProvider(providername) {
            this.dialog = true
            await new Promise(resolve => {
                const checkDialog = () => {
                    if (!this.dialog) {
                        resolve(); // Resolve the promise when dialog is closed
                    } else {
                        setTimeout(checkDialog, 100); // Check again after a short delay
                    }
                };
                checkDialog(); // Start checking dialog status
            });
            loginWithProvider(providername)
        },
        showForm() {
            this.showform = !this.showform
        },
    },
    watch: {
    // Watch for changes in i18n translations
    $i18n: {
      handler: 'updateDictionary',
      deep: true
    }
  }
}
</script>

<style>
.language-option {
    cursor: pointer;
}

.language-option:hover {
    font-weight: bold;
}
</style>