import { createApp } from 'vue'
import App from './App.vue'
import { createPinia } from 'pinia';

import vuetify from './plugins/vuetify'
import { loadFonts } from './plugins/webfontloader'
import router from "./router";
import { createI18n } from 'vue-i18n';
import './styles.css';

const messages = {
  en: require('./locales/en.json'),
  no: require('./locales/no.json')
};

const i18n = createI18n({
  locale: 'no',
  fallbackLocale: 'no',
  messages
});
export default i18n; 

loadFonts()
const app = createApp(App)
app.use(vuetify)
app.use(router)
app.use(createPinia());
app.use(i18n)
app.mount('#app')
